import "./about.scss";

export const About = () => {
  return (
    <div className="about">
      <div className="wrapper">
        <div className="wrapper">
          <img src="./assets/images/face.png" alt="face" />
          <p>Creative Technologist</p>
          <ul className="social">
            <li>
              <a
                href="https://www.linkedin.com/in/johnparkerprofile/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./assets/images/linkedin.svg" alt="linked" />
              </a>
            </li>
            {/* <li>
              <a
                href="https://www.instagram.com/johnny_parkee_makes/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./assets/images/insta.png" alt="insta" />
              </a>
            </li> */}
          </ul>
        </div>

        <ul>
          <li>⚡️ Electrical & Electonics</li>
          <li>💻 Full Stack Dev</li>
          <li>📱 IOT </li>
          <li>💡 Bright Ideas</li>
          <li>🌱 Eco Friendly</li>
        </ul>
      </div>
    </div>
  );
};
