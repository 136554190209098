/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import Tilt from "react-parallax-tilt";

import "./home.scss";

import { About } from "../about/about";
import { Drawer } from "@mui/material";
import { gsap } from "gsap";
import Sidebar from "../sidebar/sidebar";
import CancelIcon from "@mui/icons-material/Cancel";
import Card from "../card/card";
import Skills from "../skills/skills";

const isMobile = require("is-mobile");

const projectData = [
  {
    title: "MyThyroid ",
    tag: "Health Tracker App",
    imgSrc: "./assets/images/thyroid_3.png",
    link: "",
    classes: "thyroid",
    info: `MyThyroid is an application built for iOS & Android to the track symptoms, medication and day-to-day experience of people living with thyroid conditions.
    <br></br>
    This was a personal project: I have recently been diagnosed with a thyroid condition and felt inspired to create something I would find useful.
    <br></br>
    Features include calendar entries, timelines and dosage tracking for medication, and a timelapse tool to help keep track of facial changes. 
    <br></br>
    This project was created with Expo & React Native over the course of a couple of weeks
    <div style="width: 100%; position: absolute; display: flex; justify-content: space-evenly; flex-direction: row; margin-top: 2rem; left: -.5rem;">
    <img src="./assets/images/thyroid-menu.jpeg" style="width: 20rem;  max-height: none;"/>
    <img src="./assets/images/thyroid-symptom.jpeg" style="width: 20rem; max-height: none;"/>
    </div>
             `,
  },
  {
    title: "Ratatoskr's Ruin",
    tag: "Global Game Jam 2023 | Theme 'roots'",
    imgSrc: "./assets/images/ratatoskrs_ruin.png",
    link: "",
    classes: "",
    info: `As a part of Global Game Jam 2023, our team of 4 completed a game over the course of a weekend. The theme of the game was revealed to be 'Roots'.
          <br></br>
          The result is 'Ratatoskr's Ruin', a Norse-themed root beer brewing game. The aim is to keep the brew balanced in order to keep the Nine Realms of Norse mythology happy.
          You play as Ratatoskr, the squirrel tasked with keeping a balanced brew by adding one of six ingredients, which each have different effects on the final product.
          Loki the trickster god also plays havoc with the brew disrupting the balance.
         <br></br>
         The programming, artwork, recordings and soundtrack are all original.
         <a href="https://globalgamejam.org/2023/games/ratatoskrs-ruin-mythological-root-beer-brewing-sim-4" 
            target="_blank"
            rel="noreferrer"
         git>
         GGJ Jam Page
         </a>
         <br></br> <strong>The Tech</strong> This project utilised 'Procreate' for Art, 'Logic Pro' for music and 'GameMaker' for the Game Engine.
         <img src="./assets/images/ratatoskr.png" style="width: 10rem; position: absolute; right: 2rem;"/>
         `,
  },
  {
    title: "partyvib.es",
    tag: "Queue music with voting",
    imgSrc: "./assets/images/partyvibes-1.png",
    link: "",
    classes: "pv",
    info: `This was a personal project built to combat queued music skipping and encourage a democratic approach to party playlists.
    <br></br> 
   partyvib.es allowed users who have joined a virtual room (via a room code or QR code) to queue up music from YouTube (experimentally Spotify) to a host (connected to speakers). Users can then upvote / downvote songs that other users have added to the playlist. 
   <br></br> 
   The host (device) would choose which song to play next based on the upvotes and then remove the song from the queue once it has been played. You can only vote on each song/video once.
   <br></br> <strong>The Tech</strong> This project utilised websockets for fast multi-directional communication, and the Google & Spotify APIS.
   `,
  },
  {
    title: "Midi-Guitar",
    tag: " Capacitive Touch Control",
    video: false,
    imgSrc: "./assets/images/v-guitar-1.jpg",
    classes: "",
    info: `The aim of this personal project was to build a touch-triggered and digitally-controlled midi guitar interface. 
    <img src="./assets/images/v-guitar-2.jpg"/>
    Capacitive touch elements were wired into an electric guitar frame and read by an arduino when contacted. These were run through a DAW (Digital Audio Workstation) to produce midi sounds which could be manipulated by the touchscreen where a player would usually strum.
    <video src="./assets/images/v-guitar-4.mp4"></video>
    Expression could be anything from pitch, modulation, volume etc.
    <br></br> 
    Sadly this project was overshadowed by other commitments, but I'm intending to return to it - with some fresher ideas (such as less sensitive touch elements).
    `,
  },
  {
    title: "Huemanatee - The Depth of colour",
    tag: "Bacon GameJam - JS game",
    imgSrc: "./assets/images/huemanatee.png",
    link: "",
    classes: "",
    info: `This game was a simple platformer built in 48 hours as an entry to BaconGameJam.
    <br></br> 
    The team consisted of a graphic artist, music producer and programmer, and the given prompt was 'Depth'. The prompt is there to give the game a direction and is only released at the start of the Jam. We wanted to do something unexpected with the prompt, so we created The Depth of Colour – a platformer in which you take on the role of a magical manatee who must navigate down through the levels, each of which features a different colour and soundscape.
    <img src="./assets/images/manatee-death.gif" style="width: 10rem; position: absolute; right: 2rem;"/>
    `,
  },
  {
    title: "Wayfinder",
    tag: "Navigation Game",
    imgSrc: "./assets/images/planejam.png",
    link: "https://dtapgames.co.uk/games/planejam/",
    classes: "",
    info: `Created entirely during a flight to Geneva from the UK. Wayfinder is a JS game where your goal is to use the stars to navigate to distant islands. 
    <br></br>
    While not nearly finished it was a fun experiment to see if we could create something that runs in such a short space of time.
    `,
  },
  {
    title: "surfbaud.io",
    tag: "Multiplayer sound generation game",
    imgSrc: "./assets/images/surfbaud.jpg",
    link: "",
    classes: "",
    info: `Created for fun for a GameJam, Surfbaud.io is an online multiplayer, surfing, shark-dodging, soundwave-generating ride of a game. 
    <br></br> 
    After connecting to the room players control a surfer who needs to keep on the wave for as long as they can, dodging any sharks that come their way by changing waves.
    Whenever they change wave, the surfer is given different generated soundwaves which match the style of wave they ride (square wave, sawtooth, etc). Users compete for a high score on the leaderboard.
    <br></br> 
    <strong>The Tech</strong>
    This game features: websockets, node servers and a lot of Javascript. It ran in browser
    <img src='./assets/images/surfbaud-surfer.gif' style="position: absolute;" />
    `,
  },
  {
    title: "Cognition",
    tag: "Mind Powered Gaming",
    imgSrc: "./assets/images/cognition.png",
    link: "",
    classes: "",
    info: `Cognition is a sponsored project (The Blake Bursery) built for Bristol University for use in outreach for engineering. 
    <a href=http://gobrainer.blogspot.com/>Original Blogspot for the project</a>
        <img src=http://3.bp.blogspot.com/-hP1I_fpweCo/VftT_UHEJjI/AAAAAAAAAng/_2n3F79_1gg/s640/final.gif />
        Cognition features repurposed EEG headsets capable of detecting brainwaves. Using the brainwaves you can determine concentration levels which are fed into the various games and devices which I built for this project.
        <br></br> 
        This project involved the design and production of a spherical robot which responded to brainwaves, which could be used for the following two games 
        <img src="./assets/images/cognition-2.png"/>
        <strong> Brain Battle</strong> 
        Two players could compete in a reverse tug-of-war, using the power of thought to push the robot towards their opponent.
        <br></br> 
        <strong> Fusion </strong >
        Two players could work together to drive the robot, with one player controlling forwards and reverse motion and the other player controlling left and right. This was notoriously tricky but fun.
    `,
  },
  {
    title: "BirdBrain",
    tag: "Mind Powered Gaming",
    video: true,
    imgSrc: "./assets/images/birdbrain-vid.mp4",
    classes: "",
    info: `BirdBrain was a part of the suite of games I developed for the Cognition project. It was the only game that did not use the robot, but due to its bright visuals and easy learning curve it proved popular for outreach.

    <img src="./assets/images/headset-1.jpg"/>
    Using data from the EEG headsets, a player could think the BirdBrain up or down to navigate the obstacle course. As with the other EEG games, this took some practice but was great fun once you got the hang of it.
    `,
  },
  {
    title: "Dangerzone",
    tag: "Beatle-weight Combat Robot",
    imgSrc: "./assets/images/Robot.png",
    link: "",
    classes: "robot",
    info: `This was my first foray into beetle-weight combat robotics. Coming in at 1.5kg, these robots pack a punch. 
    <br></br> 
    Dangerzone was capable of flipping 4x its weight with the use of high-speed and torque servos. Its armouring was made from HDPE (High Density Poly-Ethene). The steering was simple brushed motors and a remote control circuit.
    This robot saw entry into a few competitions in Bristol and was moderately successful.
    Could have done with some more reliable servos! 
    <img src="./assets/images/dangerzone.png" style="position: absolute; width: 40%; left: 30%;" />
    `,
  },
  {
    title: "Dangerzone III",
    tag: "Antweight Combat Robotics",
    video: true,
    imgSrc: "./assets/images/DZIII_v2.mp4",
    link: "",
    classes: "",
    info: `Antweight combat robots have to be under 150g, which presents a challenge. Dangerzone III utilised a refined version of a spring-loaded flipper.
    These are relatively rare on the scene, and it was powerful enough to send a combatant sky high – when it worked, that is. 
    <br></br> 
    Parts were generally 3D printed, as seen in the CAD model.
    It ran off a custom-fabricated chip which provided Bluetooth control, and drive for the wheel motors and flipper motor.
    The flipper motor was used to recoil the spring, which gave the flipper its kinetic launch force.
    This robot was used to compete (for fun) in Bristol, but unfortunately did not survive its final bout.
    <img src="./assets/images/dangerzone.png" style="position: absolute; width: 40%; left: 30%;" />
    `,
  },
];

const Home = () => {
  const anchor = isMobile() ? "bottom" : "right";
  const [drawOpen, setDrawOpen] = useState(false);
  const [project, setProject] = useState(0);
  const [card, setCard] = useState(1);

  useEffect(() => {
    gsap.to(".c-item", {
      rotation: 360,
      stagger: 0.5,
      duration: 1, // how long the animation lasts
      repeat: 1, // the number of repeats - this will play 3 times
      yoyo: true, // this will alternate back and forth on each repeat. Like a yoyo
    });

    const listener2: any = document.addEventListener(
      "click",
      function () {
        const videoEl = document.getElementsByTagName("video");
        Array.from(videoEl).forEach((video) => {
          if (video.paused) video.play();
        });
      },
      false
    );

    return document.removeEventListener("click", listener2);
  }, []);

  return (
    <section className="home">
      <div className="inner">
        <h1>Johnny Parker</h1>
        <Sidebar />
        <img
          className="birdbrain-gif"
          src="./assets/images/BirdBrain1.gif"
          alt="birdbrain"
        />
        {/* <div className="backdrop-2"></div> */}
        <div className="backdrop"></div>
        {/* <Tilt gyroscope> */}
        <About />
        {/* </Tilt> */}
        <h2 id="Projects">Projects</h2>
        <ul className="projects">
          {projectData.map((project, index) => (
            <li
              key={project.title}
              onClick={() => {
                setProject(index);
                !drawOpen && setDrawOpen(true);
              }}
            >
              {project.video ? (
                <video
                  //   controls
                  loop
                  className={`reel ` + project.classes}
                  src={project.imgSrc}
                />
              ) : (
                <img
                  className={`reel ` + project.classes}
                  src={project.imgSrc}
                />
              )}
              <p>
                <strong>{project.title}</strong> - {project.tag}
              </p>
            </li>
          ))}
          <li style={{ background: "none", boxShadow: "none" }}></li>
        </ul>
        <h2 id="Clients">Previous Work</h2>
        <section className="carousel">
          <div className="c-item" onClick={() => setCard(1)}>
            <img src="assets/images/drest-app.jpeg" />
          </div>
          <div className="c-item" onClick={() => setCard(2)}>
            <img src="assets/images/p&g-app.png" />
          </div>
          <div className="c-item" onClick={() => setCard(3)}>
            <img src="assets/images/easyjet-app.png" />
          </div>
          <div className="c-item" onClick={() => setCard(4)}>
            <img src="assets/images/dyson-app.png" />
          </div>
        </section>
        {card === 1 && (
          <Card
            name="Drest"
            img="assets/images/drest-app.jpeg"
            link="https://drest.com/"
            text={
              <>
                Drest is a virtual fashion game for IOS (Android upcoming) where
                the players style avatars with luxury fashion in daily
                competitions, trying to recive cudos from fellow stylists
                <br></br>
                <br></br>I worked on many aspects of this including the Tooling,
                Backend systems and application.
              </>
            }
          />
        )}
        {card === 2 && (
          <Card
            name="P&G"
            img="assets/images/p&g-app.png"
            link="https://www.supersavvyme.co.uk/"
            text={
              <>
                P&G a well known retail brand owner, needed a whitelabel project
                capable of running in multiple regions, for all of its retail
                brands.
                <br></br>
                <br></br>
                It would allow consumers to upload reciepts with the option of
                recieving coupons worth product value in the site.
              </>
            }
          />
        )}
        {card === 3 && (
          <Card
            name="easyJet"
            img="assets/images/easyjet-app.png"
            link="https://www.easyjet.com/en/cheap-flights"
            text={
              <>
                I've worked on multiple microapps which support the booking
                process in the easyjet system.
                <br></br>
                <br></br>As a nice example of work which is accessable without
                buying flights, here is the 'cheap flights' portal... something
                myself and the team were quite proud of.
              </>
            }
          />
        )}
        {card === 4 && (
          <Card
            name="Dyson"
            img="assets/images/dyson-app.png"
            link="https://www.dyson.co.jp/supporthome.aspx"
            text={
              <>
                I worked with a great small team at Valtech upgrading the dyson
                retail experience over 3 years. This spanned several wildly
                varied projects.
                <br></br>
                <br></br>I lead a team building the 'Support Home' for products
                which is still in use on the high traffic JP site, there are 40
                other regions which also fell under our bracket, many of them
                making use of this tool.
              </>
            }
          />
        )}
        <h2 id="Skills">Skills</h2>
        <Skills />
      </div>
      <Drawer
        anchor={anchor}
        open={drawOpen}
        onClose={() => setDrawOpen(false)}
      >
        <section className="project-drawer">
          <CancelIcon
            className="close-drawer"
            onClick={() => setDrawOpen(false)}
          />
          {projectData[project].link ? (
            <iframe
              src={projectData[project].link}
              title={projectData[project].title}
            ></iframe>
          ) : projectData[project].video ? (
            <video
              loop
              className={`reel ` + projectData[project]}
              src={projectData[project].imgSrc}
            />
          ) : (
            <img
              alt={projectData[project].title}
              src={projectData[project].imgSrc}
            />
          )}
          <h2
            dangerouslySetInnerHTML={{ __html: projectData[project].title }}
          />
          <p dangerouslySetInnerHTML={{ __html: projectData[project].info }} />
        </section>
      </Drawer>
    </section>
  );
};

export default Home;
