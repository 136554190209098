import React from "react";

import "./skills.scss";

const languages = ["TypeScript", "JS", "C#", "ZBasic for ESP8266"];
const experience = [
  "React",
  "Node.js",
  "SparkAR",
  "Unity",
  "pixi.js",
  "three.js",
  "GraphQl",
  "AWS",
  "mongo",
  "SQL",
  "postgresSQL",
  "Remix",
  "apollo",
];
const CMS = ["Contentful", "Sitecore", "Umbraco"];
const hardware = [
  "Prototyping",
  "CAD",
  "EagleCAD",
  "3D Printing",
  "Board Fab",
  "Soldering",
  "Wiring",
  "IOT",
  "ESP8266",
];
const tools = ["Autodesk", "Logic Pro", "Photoshop"];
const other = [
  "Graphic Design",
  "Procreate",
  "Mixing",
  "Sound Design",
  "Composition",
];

const Skills = () => {
  return (
    <section className="skills">
      <div>
        <h2>Software</h2>
        <h3>Languages</h3>
        <ul>
          {languages.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
        <h3>Experience</h3>
        <ul>
          {experience.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
        <h3>CMS - Content Management</h3>
        <ul>
          {CMS.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Hardware</h2>
        <ul>
          {hardware.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Tools</h2>
        <ul>
          {tools.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Other</h2>
        <ul>
          {other.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Skills;
