import "./style/_resets.scss";
import "./style/animations.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./components/home/home";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <div className="header-wrapper"></div>
        <Home />
      </div>
    </QueryClientProvider>
  );
};

export default App;
