import React from "react";

import "./card.scss";

const Card = ({ name, img, link, text }) => {
  return (
    <div className="c-card">
      <div className="wrapper">
        <img src={img} alt="card" />
        <div>
          <div className="name">{name}</div>
          <p>{text}</p>
        </div>
      </div>
      <a href={link}>Project</a>
    </div>
  );
};

export default Card;
